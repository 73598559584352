import { createSelector } from '@ngrx/store';
import { ContentActions } from '../actions/content-actions';
import { UserActions } from '../actions/user.actions';
import { PayloadAction } from '../interfaces/payload-action.interface';
import { GlobalState } from '../store';

export interface ErrorState {
    appErrors: object;
    userErrors: object;
    authErrors: object;
    owErrors: object;
    dotaErrors: object;
    leagueErrors: object;
    fortniteErrors: object;
    valorantErrors: object;
    referralErrors: object;
    contentErrors: object;
    owNotificationsErrors: object;
    dotaNotificationsErrors: object;
    leagueNotificationsErrors: object;
}

export const errorInitialState: ErrorState = {
    appErrors: {},
    userErrors: {},
    authErrors: {},
    owErrors: {},
    dotaErrors: {},
    leagueErrors: {},
    fortniteErrors: {},
    valorantErrors: {},
    referralErrors: {},
    contentErrors: {},
    owNotificationsErrors: {},
    dotaNotificationsErrors: {},
    leagueNotificationsErrors: {}
};

export function errorReducer(state: ErrorState = errorInitialState, action: PayloadAction): ErrorState {
    switch (action.type) {
        case UserActions.USER_ERROR_NEW:
            return {
                ...state,
                userErrors: {
                    ...state.userErrors,
                    [action.payload.type]: action.payload.error || true
                }
            };
        case UserActions.USER_ERROR_RESET:
            return {
                ...state,
                userErrors: {
                    ...state.userErrors,
                    [action.payload.type]: false
                }
            };
        case ContentActions.CONTENT_ERROR_NEW:
            return {
                ...state,
                contentErrors: {
                    ...state.contentErrors,
                    [action.payload.type]: action.payload.error || true
                }
            };
        case ContentActions.CONTENT_ERROR_RESET:
            return {
                ...state,
                contentErrors: {
                    ...state.contentErrors,
                    [action.payload.type]: false
                }
            };
        default:
            return state;
    }
}

const getAppErrors = (state: GlobalState) => state.error.appErrors;
const getUserErrors = (state: GlobalState) => state.error.userErrors;
const getAuthErrors = (state: GlobalState) => state.error.authErrors;
const getDotaErrors = (state: GlobalState) => state.error.dotaErrors;
const getOwErrors = (state: GlobalState) => state.error.owErrors;
const getLeagueErrors = (state: GlobalState) => state.error.leagueErrors;
const getFortniteErrors = (state: GlobalState) => state.error.fortniteErrors;
const getValorantErrors = (state: GlobalState) => state.error.valorantErrors;
const getReferralErrors = (state: GlobalState) => state.error.referralErrors;
const getContentErrors = (state: GlobalState) => state.error.contentErrors;
const getOWNotificationsErrors = (state: GlobalState) => state.error.owNotificationsErrors;
const getDotaNotificationsErrors = (state: GlobalState) => state.error.dotaNotificationsErrors;
const getLeagueNotificationsErrors = (state: GlobalState) => state.error.leagueNotificationsErrors;

export const appErrorsSelector = createSelector(getAppErrors, appErrors => appErrors);
export const userErrorsSelector = createSelector(getUserErrors, userErrors => userErrors);
export const authErrorsSelector = createSelector(getAuthErrors, authErrors => authErrors);
export const owErrorsSelector = createSelector(getOwErrors, owErrors => owErrors);
export const dotaErrorsSelector = createSelector(getDotaErrors, errors => errors);
export const leagueErrorsSelector = createSelector(getLeagueErrors, errors => errors);
export const fortniteErrorsSelector = createSelector(getFortniteErrors, errors => errors);
export const valorantErrorsSelector = createSelector(getValorantErrors, errors => errors);
export const referralErrorSelector = createSelector(getReferralErrors, referralErrors => referralErrors);
export const contentErrorSelector = createSelector(getContentErrors, contentErrors => contentErrors);
export const owNotificationsErrorSelector = createSelector(getOWNotificationsErrors, errors => errors);
export const dotaNotificationsErrorSelector = createSelector(getDotaNotificationsErrors, errors => errors);
export const leagueNotificationsErrorSelector = createSelector(getLeagueNotificationsErrors, errors => errors);
