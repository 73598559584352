import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../store';

@Injectable()

export class ContentActions {
    static CONTENT_ERROR_NEW = 'CONTENT_ERROR_NEW';
    static CONTENT_ERROR_RESET = 'CONTENT_ERROR_RESET';

    static MAIN_SIGNED_S3_URL_GET = 'MAIN_SIGNED_S3_URL_GET';
    static MAIN_SIGNED_S3_URL_GET_SUCCESS = 'MAIN_SIGNED_S3_URL_GET_SUCCESS';

    static MAIN_THUMBNAIL_RESIZE = 'MAIN_THUMBNAIL_RESIZE';
    static MAIN_THUMBNAIL_RESIZE_SUCCESS = 'MAIN_THUMBNAIL_RESIZE_SUCCESS';

    constructor(private store: Store<GlobalState>) {
        // super();
    }

    contentErrorNew(errorData: any) {
        return {
            type: ContentActions.CONTENT_ERROR_NEW,
            payload: errorData
        };
    }

    contentErrorReset(errorData: any) {
        return this.store.dispatch({
            type: ContentActions.CONTENT_ERROR_RESET,
            payload: errorData
        });
    }

    signedS3UrlGet(payload: any) {
        return this.store.dispatch({
            type: ContentActions.MAIN_SIGNED_S3_URL_GET,
            payload: payload
        });
    }

    signedS3UrlGetSuccess(payload: any) {
        return {
            type: ContentActions.MAIN_SIGNED_S3_URL_GET_SUCCESS,
            payload: payload
        };
    }

    thumbnailResize(payload: any) {
        return this.store.dispatch({
            type: ContentActions.MAIN_THUMBNAIL_RESIZE,
            payload: payload
        });
    }

    thumnailResizeSuccess() {
        return {
            type: ContentActions.MAIN_THUMBNAIL_RESIZE_SUCCESS
        };
    }
}