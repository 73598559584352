import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { userErrorsSelector } from '../reducers/error.reducer';
import {
    contactLoadingSelector,
    contactSelector,
    isLoggedInSelector,
    profileChangeDataLoadingSelector,
    userAccountSelector,
    userRolesSelector,
    UserState,
    userUpdateLoadingSelector
} from '../reducers/user.reducer';
import { Account } from '../../models/account.model';
import { Contact } from '../../models/contact.model';
import { UserRoles } from '../../models/user-role-map.model';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';
import { GlobalState } from '../store';

@Injectable()
export class UserSelectors {
    public error$: Observable<any>;
    public account$: Observable<Account>;
    public isLoggedIn$: Observable<boolean>;
    public profileChangeLoading$: Observable<boolean>;
    public contact$: Observable<Contact>;
    public contactLoading$: Observable<any>;
    public userRoles$: Observable<UserRoles>;
    public userUpdateLoading$: Observable<boolean>;

    constructor(private store: Store<GlobalState>) {
        this.error$ = store.pipe(select(userErrorsSelector));
        this.account$ = store.pipe(select(userAccountSelector));
        this.isLoggedIn$ = store.pipe(select(isLoggedInSelector));
        this.profileChangeLoading$ = store.pipe(select(profileChangeDataLoadingSelector));
        this.contact$ = store.pipe(select(contactSelector));
        this.contactLoading$ = store.pipe(select(contactLoadingSelector));
        this.userRoles$ = store.pipe(select(userRolesSelector));
        this.userUpdateLoading$ = store.pipe(select(userUpdateLoadingSelector));
    }
}

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectAccountSubstateState = createSelector(
    selectUserState,
    (state: UserState) => state.account
);
export const selectIsPremium = createSelector(
    selectUserState,
    (state: UserState) => state.isPremium || state.isAdmin || state.isManager || state.isPlayer
);
